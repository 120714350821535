import RegistrationService from "../services/registration.service";

// Action to get registration by id
export const readRegistration = (id) => (dispatch) => {
    return RegistrationService.readRegistration(id).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to search registrations
export const searchRegistrations = (search) => (dispatch) => {
    return RegistrationService.searchRegistrations(search).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all registrations
export const readRegistrations = () => (dispatch) => {
    return RegistrationService.readRegistrations().then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to create the registration
export const createRegistration = (registration) => (dispatch) => {
    return RegistrationService.createRegistration(registration).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        console.log(error.message);
        return Promise.reject(error);
    });
}

// Action to update the registration
export const updateRegistration = (id, registration) => (dispatch) => {
    return RegistrationService.updateRegistration(id, registration).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to delete the registration
export const deleteRegistration = (id) => (dispatch) => {
    return RegistrationService.deleteRegistration(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Actino to enable the registration
export const enableRegistration = (id) => (dispatch) => {
    return RegistrationService.enableRegistration(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to disable the registration
export const disableRegistration = (id) => (dispatch) => {
    return RegistrationService.disableRegistration(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}