import CredentialService from "../services/credential.service";

// Action to get credential by id
export const readCredential = (id) => (dispatch) => {
    return CredentialService.readCredential(id).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to search apple credentials
export const searchAppleCredentials = (search) => (dispatch) => {
    return CredentialService.searchAppleCredentials(search).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to search google credentials
export const searchGoogleCredentials = (search) => (dispatch) => {
    return CredentialService.searchGoogleCredentials(search).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to search credentials
export const searchCredentials = (search) => (dispatch) => {
    return CredentialService.searchCredentials(search).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all credentials
export const readCredentials = () => (dispatch) => {
    return CredentialService.readCredentials().then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to create the credential
export const createCredential = (credential) => (dispatch) => {
    return CredentialService.createCredential(credential).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        console.log(error.message);
        return Promise.reject(error);
    });
}

// Action to update the credential
export const updateCredential = (id, credential) => (dispatch) => {
    return CredentialService.updateCredential(id, credential).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to delete the credential
export const deleteCredential = (id) => (dispatch) => {
    return CredentialService.deleteCredential(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Actino to enable the credential
export const enableCredential = (id) => (dispatch) => {
    return CredentialService.enableCredential(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to disable the credential
export const disableCredential = (id) => (dispatch) => {
    return CredentialService.disableCredential(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}