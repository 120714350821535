import ProgramService from "../services/program.service";

// Action to get program by id
export const readProgram = (id) => (dispatch) => {
    return ProgramService.readProgram(id).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to search programs
export const searchPrograms = (search) => (dispatch) => {
    return ProgramService.searchPrograms(search).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all programs
export const readPrograms = () => (dispatch) => {
    return ProgramService.readPrograms().then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to create the program
export const createProgram = (program) => (dispatch) => {
    return ProgramService.createProgram(program).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        console.log(error.message);
        return Promise.reject(error);
    });
}

// Action to update the program
export const updateProgram = (id, program) => (dispatch) => {
    return ProgramService.updateProgram(id, program).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to delete the program
export const deleteProgram = (id) => (dispatch) => {
    return ProgramService.deleteProgram(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Actino to enable the program
export const enableProgram = (id) => (dispatch) => {
    return ProgramService.enableProgram(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to disable the program
export const disableProgram = (id) => (dispatch) => {
    return ProgramService.disableProgram(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}