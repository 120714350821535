import AdminService from "../services/admin.service";
import {
    DELETE_ADMIN_SUCCESS,
    READ_ADMINS_SUCCESS,
    CREATE_ADMIN_SUCCESS,
    UPDATE_ADMIN_SUCCESS
} from "./types";

// Action to get admin by id
export const readAdmin = (id) => (dispatch) => {
    return AdminService.readAdmin(id).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all admins
export const readAdmins = () => (dispatch) => {
    return AdminService.readAdmins().then((data) => {
        dispatch({
            type: READ_ADMINS_SUCCESS,
            payload: data.result
        });
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to create the admin
export const createAdmin = (admin) => (dispatch) => {
    return AdminService.createAdmin(admin).then((data) => {
        if (data.result) {
            dispatch({
                type: CREATE_ADMIN_SUCCESS,
                payload: data.result
            });
        }
        return Promise.resolve(data);
    }, (error) => {
        console.log(error.message);
        return Promise.reject(error);
    });
}

// Action to update the admin
export const updateAdmin = (id, admin) => (dispatch) => {
    return AdminService.updateAdmin(id, admin).then((data) => {
        dispatch({
            type: UPDATE_ADMIN_SUCCESS,
            payload: data.result
        });
        return Promise.resolve(data.result);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to delete the admin
export const deleteAdmin = (id) => (dispatch) => {
    return AdminService.deleteAdmin(id).then((data) => {
        dispatch({
            type: DELETE_ADMIN_SUCCESS,
            payload: id
        });
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Actino to enable the admin
export const enableAdmin = (id) => (dispatch) => {
    return AdminService.enableAdmin(id).then((data) => {
        dispatch({
            type: UPDATE_ADMIN_SUCCESS,
            payload: data.result
        });
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to disable the admin
export const disableAdmin = (id) => (dispatch) => {
    return AdminService.disableAdmin(id).then((data) => {
        dispatch({
            type: UPDATE_ADMIN_SUCCESS,
            payload: data.result
        });
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to reset the admin
export const resetAdmin = (id) => (dispatch) => {
    return AdminService.resetAdmin(id).then((data) => {
        dispatch({
            type: UPDATE_ADMIN_SUCCESS,
            payload: data.result
        });
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}