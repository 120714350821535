import React, { useEffect, useState } from 'react';
import { Layout, Popconfirm, message, Button, Table, Space } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useNavigate } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch } from 'react-redux';
import { readOrganizations, deleteOrganization, disableOrganization, enableOrganization } from '../actions/organization.js';
import { green, red } from '@ant-design/colors';
import {
    CheckOutlined,
    CloseOutlined
} from '@ant-design/icons';


function Organizations() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { Content } = Layout;
    const [reloadFlag, setReloadFlag] = useState(new Date());
    const [dataSource, setDataSource] = useState([]);

    const deleteOrganizationAction = (id) => {
        dispatch(deleteOrganization(id)).then(response => {
            message.success('Organization deleted successfully');
            setReloadFlag(new Date());
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(e.message);
            }
        });
    }
    const enableOrganizationAction = (id) => {
        dispatch(enableOrganization(id)).then(response => {
            message.success('Organization enabled successfully');
            setReloadFlag(new Date());
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(e.message);
            }
        });

    }
    const disableOrganizationAction = (id) => {
        dispatch(disableOrganization(id)).then(response => {
            message.success('Organization is disabled successfully');
            setReloadFlag(new Date());
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(e.message);
            }
        });
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Slug',
            dataIndex: 'slug',
            key: 'slug',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                if (record.status) {
                    return <CheckOutlined style={{ color: green[5] }} />;
                } else {
                    return <CloseOutlined style={{ color: red[5] }} />;
                }
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return <Space>
                    <Button type="primary" onClick={() => navigate(`/organization/${record.id}`)}>Edit</Button>
                    <Popconfirm
                        title="Are you sure to delete this organization?"
                        onConfirm={() => deleteOrganizationAction(record.id)}
                        okText="Delete"
                        cancelText="Cancel"
                    >
                        <Button danger type="primary">Delete</Button>
                    </Popconfirm>
                    {record.status ? <Popconfirm
                        title="Are you sure to disable this organization?"
                        onConfirm={() => disableOrganizationAction(record.id)}
                        okText="Disable"
                        cancelText="Cancel"
                    ><Button type="primary" danger>Disable</Button></Popconfirm> : <Popconfirm
                        title="Are you sure to enable this organization?"
                        onConfirm={() => enableOrganizationAction(record.id)}
                        okText="Enable"
                        cancelText="Cancel"
                    ><Button type="primary">Enable</Button></Popconfirm>}
                    <Button type="priamry" style={{ backgroundColor: green[5] }} onClick={() => {
                        navigate(`/clients?organization=${record.id}`);
                    }}>Clients</Button>
                </Space>
            }
        }
    ];

    useEffect(() => {
        dispatch(readOrganizations()).then((response) => {
            setDataSource(response.result);
        }).catch((error) => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(e.message);
            }
        });
    }, [dispatch, reloadFlag]);

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <PageHeader
                        className="site-page-header"
                        onBack={() => navigate('/dashboard')}
                        title="Organizations"
                        subTitle="Manage organizations"
                        extra={[
                            <Button key="create" type="primary" onClick={() => navigate('/organization')}>New</Button>
                        ]}
                    />
                    <Table
                        rowKey={record => record.id}
                        dataSource={dataSource}
                        columns={columns} />
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default Organizations;