import {
    READ_ADMINS_SUCCESS,
    DELETE_ADMIN_SUCCESS,
    CREATE_ADMIN_SUCCESS,
    UPDATE_ADMIN_SUCCESS,
    LOGOUT
} from "../actions/types";

const initialState = { admins: null };

function adminReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LOGOUT:
            return {
                ...state,
                admins: null,
            };
        case UPDATE_ADMIN_SUCCESS:
            return {
                ...state,
                admins: state.admins ? state.admins.map(admin => {
                    if (admin.id === payload.id) {
                        return payload;
                    }
                    return admin;
                }) : null
            };
        case READ_ADMINS_SUCCESS:
            return {
                ...state,
                admins: payload
            };
        case CREATE_ADMIN_SUCCESS:
            return {
                ...state,
                admins: [...state.admins, payload]
            };
        case DELETE_ADMIN_SUCCESS:
            return {
                ...state,
                admins: state.admins.filter(admin => admin.id !== payload)
            };
        default:
            return state;
    }
}
export default adminReducer;