import { Row, Col, Button, Form, Input, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import './login.page.scss';
import { login } from '../actions/auth';
import { useDispatch } from 'react-redux';

const Login = () => {
    let navigate = useNavigate();
    let dispatch = useDispatch();

    const onFinish = (values) => {
        console.log('[Login]:', values);
        dispatch(login(values.email, values.password)).then(function (response) {
            navigate('/dashboard');
        }).catch(error => {
            console.log('[Login]', error);
            message.error('Login failed, please check your email and password');
        })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Row className="login__wrapper">
            <Col className="login__panel" span={8} offset={8}>
                <Form
                    name="basic"
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 12,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <h1 className="login__title">K2 Loyalty Admin Panel</h1>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your email!',
                            },
                        ]}
                    >
                        <Input type="email" />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 6,
                            span: 12,
                        }}
                    >
                        <Button style={{ width: '100%' }} type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
};

export default Login;