import React, { useEffect, useState } from 'react';
import MemberSidebar from './components/member.sidebar.component';
import { PageHeader } from '@ant-design/pro-layout';
import { Layout, Button, Form, Input, message, Switch, InputNumber, Select, ColorPicker, Tabs } from 'antd';
import MemberFooter from './components/member.footer.component';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { formatSwitchInForm } from '../helpers/form';
import { green } from '@ant-design/colors';
import { createProgram, readProgram, updateProgram } from '../actions/program';
import SearchSelectField from './components/field.select';
import { searchOrganizations } from '../actions/organization';
import { searchAppleCredentials, searchGoogleCredentials } from '../actions/credential';
import { DeleteOutlined } from '@ant-design/icons';

const formFullItemLayout = {
    wrapperCol: {
        span: 24,
    }
};
const formLayout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 16,
    },
};
const formItemLayout = {
    labelCol: {
        span: 4
    },
    wrapperCol: {
        span: 20
    },
};
const formItemLayoutWithOutLabel = {
    wrapperCol: {
        span: 20,
        offset: 4,
    },
};

function Program() {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [enableIOS, setEnableIOS] = useState(true);
    const [enableGoogle, setEnableGoogle] = useState(true);
    const defaultNewProgramTitle = 'New Program';
    const defaultNewProgramSubtitle = 'Create new program manually';
    const defaultSubmitText = 'Submit';
    const [pageTitle, setPageTitle] = useState(defaultNewProgramTitle);
    const [pageSubtitle, setPageSubtitle] = useState(defaultNewProgramSubtitle);
    const [submitText, setSubmitText] = useState(defaultSubmitText);
    const [dataSource, setDataSource] = useState(null);
    const { Content } = Layout;
    const [organizationOptions, setOrganizationOptions] = useState(null);
    const [appleCredentialOptions, setAppleCredentialOptions] = useState(null);
    const [googleCredentialOptions, setGoogleCredentialOptions] = useState(null);

    useEffect(() => {
        console.log('reload');
        const programId = params.programId;
        if (typeof programId !== 'undefined') {
            setPageTitle(`Program - ${programId}`);
            setPageSubtitle('');
            setSubmitText('Update');
            dispatch(readProgram(programId)).then(response => {
                const result = response.result;
                if (typeof result.organization !== 'undefined') {
                    if (typeof result.organization._id !== 'undefined') {
                        setOrganizationOptions([{
                            label: `${result.organization.name} (${result.organization.slug})`,
                            value: result.organization._id,
                        }]);
                        result.organization = result.organization._id;
                    }
                }
                if (typeof result.appleCredential !== 'undefined') {
                    if (typeof result.appleCredential._id !== 'undefined') {
                        setAppleCredentialOptions([{
                            label: `${result.appleCredential.name}`,
                            value: result.appleCredential._id,
                        }]);
                        result.appleCredential = result.appleCredential._id;
                    }
                }
                form.setFieldsValue(result);
                if (result.enableGoogle) {
                    setEnableGoogle(true);
                } else {
                    setEnableGoogle(false);
                }
                if (result.enableIOS) {
                    setEnableIOS(true);
                } else {
                    setEnableIOS(false);
                }
                setDataSource(result);
                console.log('read');
            }).catch(error => {
                try {
                    const responseMessage = error.response.data.message;
                    message.error(responseMessage);
                    navigate('/programs');
                } catch (e) {
                    console.log(e);
                    message.error(e.message);
                    navigate('/programs');
                }
            });
        } else {
            setPageTitle(defaultNewProgramTitle);
            setPageSubtitle(defaultNewProgramSubtitle);
            setSubmitText(defaultSubmitText);
        }
    }, [params]);

    const onFinish = (values) => {
        let formattedValues = formatSwitchInForm(values, ['status', 'enableIOS', 'enableGoogle']);
        if (typeof formattedValues.iOSPassSetting === 'object') {
            if (typeof formattedValues.iOSPassSetting.foregroundColor === 'object') {
                formattedValues.iOSPassSetting.foregroundColor = formattedValues.iOSPassSetting.foregroundColor.toRgbString();
            }
            if (typeof formattedValues.iOSPassSetting.backgroundColor === 'object') {
                formattedValues.iOSPassSetting.backgroundColor = formattedValues.iOSPassSetting.backgroundColor.toRgbString();
            }
        }
        console.log(formattedValues);
        if (typeof params.programId !== 'undefined') {
            dispatch(updateProgram(params.programId, formattedValues)).then(response => {
                try {
                    const result = response.result;
                    if (typeof result.id !== 'undefined') {
                        message.success('Program updated successfully');
                        // refresh the page
                        navigate(`/program/${result.id}`);
                    }
                } catch (e) {
                    console.log(e);
                    message.error(e.message);
                    navigate('/programs');
                }
            }).catch(error => {
                try {
                    console.log(error);
                    const responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log(e);
                    message.error(e.message);
                }
            });
        } else {
            dispatch(createProgram(formattedValues)).then(response => {
                try {
                    const result = response.result;
                    if (typeof result.id !== 'undefined') {
                        message.success('Program created successfully');
                        navigate(`/program/${result.id}`);
                    }
                } catch (e) {
                    console.log(e);
                    message.error(e.message);
                    navigate('/programs');
                }
            }).catch(error => {
                try {
                    console.log(error);
                    const responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log(e);
                    message.error(e.message);
                }
            });
        }
    }

    const onFinishFailed = ({ values, errorFields, outOfDate }) => {
        console.log('Failed:', errorFields);
        if (Array.isArray(errorFields)) {
            errorFields.forEach(field => {
                if (typeof field.errors !== 'undefined' && Array.isArray(field.errors) && field.errors.length > 0) {
                    field.errors.forEach(error => {
                        message.error(error);
                    });
                }
            });
        }
    }
    let extraButtons = [
        <Button key="cancel" type="primary" danger onClick={() => navigate('/programs')}>Cancel</Button>,
        <Button key="submit" type="primary" htmlType="submit">{submitText}</Button>,
    ];
    if (typeof dataSource !== 'undefined' && dataSource) {
        extraButtons.push(<Link key="clients" to={``} target="_blank"><Button type="priamry" style={{ backgroundColor: green[5] }}>Program Page</Button></Link>);
    }

    const formItems = [
        {
            forceRender: true,
            label: 'General',
            key: 'general',
            children: <>
                <Form.Item
                    label="Name"
                    name="name"
                    required={true}
                    rules={[{ required: true, message: 'Please input an program name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Slug"
                    name="slug"
                    required={true}
                    rules={[
                        { required: true, message: 'Please input an program slug!' },
                        // Only allow hypen and lowercase letters and numbers and underscore
                        { pattern: /^[a-z0-9_-]+$/, message: 'Please input a valid program slug! /^[a-z0-9_-]+$/' }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Organization"
                    name="organization"
                    required={true}
                    rules={[
                        {
                            required: true,
                            message: 'Please input organization name',
                        },
                    ]}
                >
                    <SearchSelectField
                        showSearch={true}
                        placeholder="Select organization"
                        allowClear={true}
                        fetchOptions={(value) => dispatch(searchOrganizations(value))}
                        defaultOptions={organizationOptions}
                        displayOptions={(data) => {
                            try {
                                return data.result.map((item) => {
                                    return {
                                        label: `${item.name} (${item.slug})`,
                                        value: item.id,
                                    };
                                });
                            } catch (e) {
                                console.log(e);
                                return [];
                            }
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="Status"
                    name="status"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
                <Form.Item
                    label="Enable iOS?"
                    name="enableIOS"
                    valuePropName="checked"
                >
                    <Switch onChange={(value) => setEnableIOS(value)} />
                </Form.Item>
                {enableIOS && <Form.Item
                    label="Apple Credential"
                    name="appleCredential"
                    required={true}
                    rules={[
                        {
                            required: true,
                            message: 'Please input credential name',
                        },
                    ]}
                >
                    <SearchSelectField
                        showSearch={true}
                        placeholder="Select credential"
                        allowClear={true}
                        fetchOptions={(value) => dispatch(searchAppleCredentials(value))}
                        defaultOptions={appleCredentialOptions}
                        displayOptions={(data) => {
                            try {
                                return data.result.map((item) => {
                                    return {
                                        label: `${item.name}`,
                                        value: item.id,
                                    };
                                });
                            } catch (e) {
                                console.log(e);
                                return [];
                            }
                        }}
                    />
                </Form.Item>}

                <Form.Item
                    label="Enable Google?"
                    name="enableGoogle"
                    valuePropName="checked"
                >
                    <Switch onChange={(value) => setEnableGoogle(value)} />
                </Form.Item>
                {enableGoogle && <Form.Item
                    label="Google Credential"
                    name="googleCredential"
                    required={true}
                    rules={[
                        {
                            required: true,
                            message: 'Please input credential name',
                        },
                    ]}
                >
                    <SearchSelectField
                        showSearch={true}
                        placeholder="Select credential"
                        allowClear={true}
                        fetchOptions={(value) => dispatch(searchGoogleCredentials(value))}
                        defaultOptions={googleCredentialOptions}
                        displayOptions={(data) => {
                            try {
                                return data.result.map((item) => {
                                    return {
                                        label: `${item.name}`,
                                        value: item.id,
                                    };
                                });
                            } catch (e) {
                                console.log(e);
                                return [];
                            }
                        }}
                    />
                </Form.Item>}
                <Form.Item
                    label="Program Type"
                    name="type"
                    required={true}
                    rules={[{ required: true, message: 'Please input an program type!' }]}
                >
                    <Select allowClear>
                        <Select.Option value="stamp">Stamp</Select.Option>
                        <Select.Option value="point">Point</Select.Option>
                    </Select>
                </Form.Item>
            </>
        }
    ];

    if (enableIOS) {
        formItems.push({
            forceRender: true,
            label: 'iOS Pass',
            key: 'iosPass',
            children: <>
                <Form.Item
                    label="Location"
                    style={{ marginBottom: 0 }}
                >
                    <Form.Item
                        name={['iOSPassSetting', 'location', 'latitude']}
                        style={{
                            display: 'inline-block',
                            width: 'calc(50% - 8px)',
                            marginRight: 16
                        }}
                    >
                        <InputNumber style={{ width: '100%' }} step={0.000001} placeholder="Latitude" />
                    </Form.Item>
                    <Form.Item
                        name={['iOSPassSetting', 'location', 'longitude']}
                        style={{
                            display: 'inline-block',
                            width: 'calc(50% - 8px)'
                        }}
                    >
                        <InputNumber style={{ width: '100%' }} step={0.000001} placeholder="Longitude" />
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    label="iOS pass barcodee format"
                    name={['iOSPassSetting', 'barcodeFormat']}
                >
                    <Select allowClear>
                        <Select.Option value="PKBarcodeFormatQR">QR</Select.Option>
                        <Select.Option value="PKBarcodeFormatPDF417">PDF417</Select.Option>
                        <Select.Option value="PKBarcodeFormatAztec">Aztec</Select.Option>
                        <Select.Option value="PKBarcodeFormatCode128">Code128</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Organization Name"
                    name={['iOSPassSetting', 'organizationName']}
                    required={true}
                    rules={[{ required: true, message: 'Please input an organization name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Description"
                    name={['iOSPassSetting', 'description']}
                    required={true}
                    rules={[{ required: true, message: 'Please input an description!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Logo Text"
                    name={['iOSPassSetting', 'logoText']}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Foreground Color"
                    name={['iOSPassSetting', 'foregroundColor']}
                >
                    <ColorPicker defaultValue={`#ffffff`} showText size='large' />
                </Form.Item>
                <Form.Item
                    label="Background Color"
                    name={['iOSPassSetting', 'backgroundColor']}
                >
                    <ColorPicker defaultValue={`#ffffff`} showText size='large' />
                </Form.Item>
                <Form.Item
                    label="Card Type"
                    name={['iOSPassSetting', 'cardType']}
                >
                    <Select allowClear>
                        <Select.Option value="coupon">Coupon</Select.Option>
                        <Select.Option value="eventTicket">Event Ticket</Select.Option>
                        <Select.Option value="generic">Generic</Select.Option>
                        <Select.Option value="storeCard">Store Card</Select.Option>
                    </Select>
                </Form.Item>
                <Form.List name={['iOSPassSetting', 'fields']}>
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                                <div style={{ display: 'flex' }}>
                                    <Form.Item
                                        {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                        label={index === 0 ? 'Fields' : ''}
                                        key={key}
                                        style={{ width: `calc(100% - 50px)` }}
                                    >
                                        <Form.Item
                                            {...restField}
                                            label="Position"
                                            name={[name, 'position']}
                                            fieldKey={[fieldKey, 'position']}
                                            rules={[{ required: true, message: 'Please input an position!' }]}
                                            style={{
                                                display: 'inline-block',
                                                width: 'calc(50% - 8px)',
                                                margin: '0 8px 4px 0',
                                            }}>
                                            <Select allowClear>
                                                <Select.Option value="fields">Header</Select.Option>
                                                <Select.Option value="primaryFields">Primary</Select.Option>
                                                <Select.Option value="secondaryFields">Secondary</Select.Option>
                                                <Select.Option value="auxiliaryFields">Auxiliary</Select.Option>
                                                <Select.Option value="backFields">Back</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Field Type"
                                            name={[name, 'type']}
                                            fieldKey={[fieldKey, 'type']}
                                            rules={[{ required: true, message: 'Please input an field type!' }]}
                                            style={{
                                                display: 'inline-block',
                                                width: 'calc(50% - 8px)',
                                                margin: '0 8px 4px 0',
                                            }}>
                                            <Select>
                                                <Select.Option value="number">Number</Select.Option>
                                                <Select.Option value="string">String</Select.Option>
                                                <Select.Option value="currency">Currency</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            dependencies={[['iOSPassSetting', 'fields', name, 'type']]}
                                            noStyle
                                        >
                                            {
                                                () => {
                                                    let type = form.getFieldValue(['iOSPassSetting', 'fields', name, 'type']);
                                                    // default should be string
                                                    return <>
                                                        {type === 'currency' && <Form.Item
                                                            {...restField}
                                                            label="Currency Code"
                                                            required={true}
                                                            rules={[{ required: true, message: 'Please input a currency code!' }]}
                                                            name={[name, 'currencyCode']}
                                                            fieldKey={[fieldKey, 'currencyCode']}
                                                            style={{
                                                                display: 'inline-block',
                                                                width: 'calc(50% - 8px)',
                                                                margin: '0 8px 4px 0',
                                                            }}
                                                        >
                                                            <Input />
                                                        </Form.Item>}
                                                    </>
                                                }
                                            }
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Key"
                                            name={[name, 'key']}
                                            fieldKey={[fieldKey, 'key']}
                                            rules={[
                                                { required: true, message: 'Please input an key!' },
                                                // Only allow hypen and lowercase letters and numbers and underscore
                                                { pattern: /^[a-z0-9_-]+$/, message: 'Please input a valid key! /^[a-z0-9_-]+$/' },
                                            ]}
                                            style={{
                                                display: 'inline-block',
                                                width: 'calc(50% - 8px)',
                                                margin: '0 8px 4px 0',
                                            }}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Label"
                                            name={[name, 'label']}
                                            fieldKey={[fieldKey, 'label']}
                                            style={{
                                                display: 'inline-block',
                                                width: 'calc(50% - 8px)',
                                                margin: '0 8px 4px 0',
                                            }}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            dependencies={[['iOSPassSetting', 'fields', name, 'type']]}
                                            noStyle
                                        >
                                            {
                                                () => {
                                                    let type = form.getFieldValue(['iOSPassSetting', 'fields', name, 'type']);
                                                    // default should be string
                                                    console.log(type);
                                                    return <>
                                                        {(typeof type === 'undefined' || type === 'string') ? <Form.Item
                                                            {...restField}
                                                            label="Value"
                                                            name={[name, 'value']}
                                                            fieldKey={[fieldKey, 'value']}
                                                            rules={[{ required: true, message: 'Please input an value!' }]}
                                                            style={{
                                                                display: 'inline-block',
                                                                width: 'calc(50% - 8px)',
                                                                margin: '0 0 4px'
                                                            }}
                                                        >
                                                            <Input />
                                                        </Form.Item> : <Form.Item
                                                            {...restField}
                                                            label="Value"
                                                            name={[name, 'value']}
                                                            fieldKey={[fieldKey, 'value']}
                                                            rules={[{ required: true, message: 'Please input an value!' }]}
                                                            style={{
                                                                display: 'inline-block',
                                                                width: 'calc(50% - 8px)',
                                                                margin: '0 0 4px'
                                                            }}
                                                        >
                                                            <InputNumber style={{ width: '100%' }} />
                                                        </Form.Item>}
                                                    </>
                                                }
                                            }
                                        </Form.Item>
                                    </Form.Item>
                                    <div style={{ paddingLeft: 8 }}>
                                        <Button type="dashed" onClick={() => remove(name)} style={{ width: '100%' }}><DeleteOutlined /></Button>
                                    </div>
                                </div>
                            ))}
                            <Form.Item
                                {...formFullItemLayout}
                            >
                                <Button type="dashed" onClick={() => add()} block>
                                    Add Field
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </>
        });
    }

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} {...formLayout} initialValues={{
                    "enableIOS": true,
                    "enableGoogle": true,
                    "iOSPassSetting": {
                        "barcodeFormat": "PKBarcodeFormatQR",
                        "cardType": "storeCard"
                    }
                }}>
                    <div className="site-layout-background">
                        <PageHeader
                            className="site-page-header"
                            onBack={() => navigate('/programs')}
                            title={pageTitle}
                            subTitle={pageSubtitle}
                            extra={extraButtons}
                        />
                        <Tabs
                            defaultActiveKey="general"
                            items={formItems} />
                    </div>
                </Form>
            </Content >
            <MemberFooter />
        </Layout >
    </>;
}

export default Program;