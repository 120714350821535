import authHeader from './auth-header';
import axios from "axios";
import { API_CREDENTIAL_URL } from "./config";

// API to get credential by id
const readCredential = (id) => {
    return axios.get(API_CREDENTIAL_URL + id, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get all credentials
const readCredentials = () => {
    return axios.get(API_CREDENTIAL_URL, {
        headers: authHeader()
    }).then(res => res.data);
}


// API to create credential
const createCredential = (credential) => {
    return axios
        .post(API_CREDENTIAL_URL, credential, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to update credential
const updateCredential = (id, credential) => {
    return axios
        .put(API_CREDENTIAL_URL + id, credential, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to delete credential
const deleteCredential = (id) => {
    return axios
        .delete(API_CREDENTIAL_URL + id, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to disable credential
const disableCredential = (id) => {
    return axios
        .put(API_CREDENTIAL_URL + id + '/disable', {}, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to enable credential
const enableCredential = (id) => {
    return axios
        .put(API_CREDENTIAL_URL + id + '/enable', {}, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to search credentials
const searchCredentials = (search) => {
    return axios
        .get(API_CREDENTIAL_URL + 'search/?q=' + search, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};
// API to search apple credentials
const searchAppleCredentials = (search) => {
    return axios
        .get(API_CREDENTIAL_URL + 'search-apple/?q=' + search, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to search google credentials
const searchGoogleCredentials = (search) => {
    return axios
        .get(API_CREDENTIAL_URL + 'search-google/?q=' + search, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

const credential = {
    createCredential,
    readCredential,
    readCredentials,
    updateCredential,
    deleteCredential,
    enableCredential,
    disableCredential,
    searchCredentials,
    searchAppleCredentials,
    searchGoogleCredentials
};

export default credential;