import authHeader from './auth-header';
import axios from "axios";
import { API_CLIENT_URL } from "./config";

// API to get client by id
const readClient = (id) => {
    return axios.get(API_CLIENT_URL + id, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get all clients
const readClients = () => {
    return axios.get(API_CLIENT_URL, {
        headers: authHeader()
    }).then(res => res.data);
}


// API to create client
const createClient = (client) => {
    return axios
        .post(API_CLIENT_URL, client, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to update client
const updateClient = (id, client) => {
    return axios
        .put(API_CLIENT_URL + id, client, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to delete client
const deleteClient = (id) => {
    return axios
        .delete(API_CLIENT_URL + id, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to disable client
const disableClient = (id) => {
    return axios
        .put(API_CLIENT_URL + id + '/disable', {}, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to enable client
const enableClient = (id) => {
    return axios
        .put(API_CLIENT_URL + id + '/enable', {}, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

const client = {
    createClient,
    readClient,
    readClients,
    updateClient,
    deleteClient,
    enableClient,
    disableClient,
};

export default client;