import ClientService from "../services/client.service";

// Action to get client by id
export const readClient = (id) => (dispatch) => {
    return ClientService.readClient(id).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all clients
export const readClients = () => (dispatch) => {
    return ClientService.readClients().then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to create the client
export const createClient = (client) => (dispatch) => {
    return ClientService.createClient(client).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        console.log(error.message);
        return Promise.reject(error);
    });
}

// Action to update the client
export const updateClient = (id, client) => (dispatch) => {
    return ClientService.updateClient(id, client).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to delete the client
export const deleteClient = (id) => (dispatch) => {
    return ClientService.deleteClient(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Actino to enable the client
export const enableClient = (id) => (dispatch) => {
    return ClientService.enableClient(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to disable the client
export const disableClient = (id) => (dispatch) => {
    return ClientService.disableClient(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}