import PassService from "../services/pass.service";

// Action to get pass by id
export const readPass = (id) => (dispatch) => {
    return PassService.readPass(id).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to search passes
export const searchPasses = (search) => (dispatch) => {
    return PassService.searchPasses(search).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all passes
export const readPasses = () => (dispatch) => {
    return PassService.readPasses().then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to create the pass
export const createPass = (pass) => (dispatch) => {
    return PassService.createPass(pass).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        console.log(error.message);
        return Promise.reject(error);
    });
}

// Action to update the pass
export const updatePass = (id, pass) => (dispatch) => {
    return PassService.updatePass(id, pass).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to delete the pass
export const deletePass = (id) => (dispatch) => {
    return PassService.deletePass(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}