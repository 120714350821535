import { useState } from 'react';
import { Layout, Menu } from 'antd';
import {
    HomeOutlined,
    DesktopOutlined,
    ProfileOutlined,
    LogoutOutlined,
    UserOutlined,
    HistoryOutlined,
    AppstoreOutlined,
    BorderOutlined,
    KeyOutlined,
    IdcardOutlined,
    CreditCardOutlined,
    AppleOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { logout } from "../../actions/auth";
import { isRootOrTopAdmin } from '../../helpers/user';


function MemberSidebar() {
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.auth.user);

    const { Sider } = Layout;
    const [collapsed, setCollapsed] = useState(false);

    // TODO: check if enableCrm is true or false
    return <Sider collapsible collapsed={collapsed} onCollapse={() => setCollapsed(!collapsed)}>
        <div className="logo" />
        <Menu theme="dark" mode="inline">
            <Menu.Item key="dashboard" icon={<HomeOutlined />}>
                <Link to="/dashboard">Dashboard</Link>
            </Menu.Item>
            {isRootOrTopAdmin(currentUser) && <>
                <Menu.Item key="organizations" icon={<AppstoreOutlined />}>
                    <Link to="/organizations">Organizations</Link>
                </Menu.Item>
                <Menu.Item key="clients" icon={<BorderOutlined />}>
                    <Link to="/clients">Clients</Link>
                </Menu.Item>
                <Menu.Item key="credentials" icon={<KeyOutlined />}>
                    <Link to="/credentials">Credentials</Link>
                </Menu.Item>
                <Menu.Item key="programs" icon={<IdcardOutlined />}>
                    <Link to="/programs">Programs</Link>
                </Menu.Item>
                <Menu.Item key="passes" icon={<CreditCardOutlined />}>
                    <Link to="/passes">Passes</Link>
                </Menu.Item>
                <Menu.Item key="registrations" icon={<DesktopOutlined />}>
                    <Link to="/registrations">Registrations</Link>
                </Menu.Item>
                <Menu.Item key="apple-devices" icon={<AppleOutlined />}>
                    <Link to="/apple-devices">Apple Device</Link>
                </Menu.Item>
                <Menu.Item key="admins" icon={<UserOutlined />}>
                    <Link to="/admins">Admins</Link>
                </Menu.Item>
                <Menu.Item key="roles" icon={<UserOutlined />}>
                    <Link to="/roles">Roles</Link>
                </Menu.Item>
                <Menu.Item key="log" icon={<HistoryOutlined />}>
                    <Link to="/logs">Log</Link>
                </Menu.Item>
            </>}
            <Menu.Item key="profile" icon={<ProfileOutlined />}>
                <Link to="/profile">Profile</Link>
            </Menu.Item>
            <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={() => dispatch(logout())}>Logout</Menu.Item>
        </Menu>
    </Sider>;
}

export default MemberSidebar;