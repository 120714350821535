import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import './App.scss';
import MemberSection from './pages/member.section.page.js';
import Login from './pages/login.page';
import FirstUpdatePassword from './pages/firstUpdatePassword.page';
// import Reset from './pages/reset.page';

function App() {
  return <BrowserRouter>
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="update-password" element={<FirstUpdatePassword />} />
      {/* <Route path="activate/:id/:token" element={<Reset mode="activate" />} />
      <Route path="reset/:id/:token" element={<Reset mode="reset" />} /> */}
      <Route path="*" element={<MemberSection />} />
    </Routes>
  </BrowserRouter>;
}

export default App;