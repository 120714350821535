import authHeader from './auth-header';
import axios from "axios";
import { API_PASS_URL } from "./config";

// API to get pass by id
const readPass = (id) => {
    return axios.get(API_PASS_URL + id, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get all passes
const readPasses = () => {
    return axios.get(API_PASS_URL, {
        headers: authHeader()
    }).then(res => res.data);
}


// API to create pass
const createPass = (pass) => {
    return axios
        .post(API_PASS_URL, pass, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to update pass
const updatePass = (id, pass) => {
    return axios
        .put(API_PASS_URL + id, pass, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to delete pass
const deletePass = (id) => {
    return axios
        .delete(API_PASS_URL + id, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to search passes
const searchPasses = (search) => {
    return axios
        .get(API_PASS_URL + 'search/?q=' + search, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

const pass = {
    createPass,
    readPass,
    readPasses,
    updatePass,
    deletePass,
    searchPasses
};

export default pass;