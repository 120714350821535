import React, { useEffect } from 'react';
import { Layout, Form, Input, Button, message } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import MemberSidebar from './components/member.sidebar.component';
import { useNavigate } from 'react-router-dom';
import MemberFooter from './components/member.footer.component';
import { useDispatch, useSelector } from 'react-redux';
import { profileUpdateName, profileUpdatePassword } from '../actions/auth';

function Profile() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.auth.user);
    const { Content } = Layout;
    const [form] = Form.useForm();
    const [passwordForm] = Form.useForm();

    const onFinishName = (values) => {
        console.log('Success:', values);
        dispatch(profileUpdateName(currentUser ? currentUser.id : null, values.name)).then(() => {
            message.success('Name updated successfully');
        }).catch(error => {
            try {
                const errorMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                message.error(errorMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(e.message);
            }
        });
    };

    const onFinishPassword = (values) => {
        console.log('Success:', values);
        dispatch(profileUpdatePassword(currentUser ? currentUser.id : null, values.oldPassword, values.newPassword)).then(() => {
            message.success('Password updated successfully');
            passwordForm.resetFields();
        }).catch(error => {
            try {
                const errorMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                message.error(errorMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(e.message);
            }
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        message.error(JSON.stringify(errorInfo));
    };

    useEffect(() => {
        if (typeof currentUser.name !== 'undefined') {
            form.setFieldsValue({
                name: currentUser.name
            });
        }
    }, [form, currentUser.name]);


    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <PageHeader
                        className="site-page-header"
                        onBack={() => navigate('/dashboard')}
                        title="Profile"
                        subTitle="Update your profile"
                    />
                    <Form
                        form={form}
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 8 }}
                        onFinish={onFinishName}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: 'Please input your name!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit">
                                Update
                            </Button>
                        </Form.Item>
                    </Form>
                    <Form
                        form={passwordForm}
                        name="password"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 8 }}
                        onFinish={onFinishPassword}
                        onFinishFailed={onFinishFailed}
                        initialValues={{}}
                        autoComplete="off"
                    >
                        <h1 style={{ textAlign: 'center' }}>Update Password</h1>
                        <Form.Item
                            name="oldPassword"
                            label="Old Password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input.Password placeholder="Old Password" />
                        </Form.Item>
                        <Form.Item
                            name="newPassword"
                            label="New Password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                            hasFeedback
                        >
                            <Input.Password placeholder='New Password' />
                        </Form.Item>
                        <Form.Item
                            name="confirmNewPassword"
                            label="Confirm New Password"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password placeholder='Confirm New Password' />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit">
                                Update
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default Profile;