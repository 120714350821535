import authHeader from './auth-header';
import axios from "axios";
import { API_ORGANIZATION_URL } from "./config";

// API to get organization by id
const readOrganization = (id) => {
    return axios.get(API_ORGANIZATION_URL + id, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get all organizations
const readOrganizations = () => {
    return axios.get(API_ORGANIZATION_URL, {
        headers: authHeader()
    }).then(res => res.data);
}


// API to create organization
const createOrganization = (organization) => {
    return axios
        .post(API_ORGANIZATION_URL, organization, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to update organization
const updateOrganization = (id, organization) => {
    return axios
        .put(API_ORGANIZATION_URL + id, organization, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to delete organization
const deleteOrganization = (id) => {
    return axios
        .delete(API_ORGANIZATION_URL + id, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to disable organization
const disableOrganization = (id) => {
    return axios
        .put(API_ORGANIZATION_URL + id + '/disable', {}, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to enable organization
const enableOrganization = (id) => {
    return axios
        .put(API_ORGANIZATION_URL + id + '/enable', {}, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to search organizations
const searchOrganizations = (search) => {
    return axios
        .get(API_ORGANIZATION_URL + 'search/?q=' + search, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

const organization = {
    createOrganization,
    readOrganization,
    readOrganizations,
    updateOrganization,
    deleteOrganization,
    enableOrganization,
    disableOrganization,
    searchOrganizations
};

export default organization;