import authHeader from './auth-header';
import axios from "axios";
import { API_PROGRAM_URL } from "./config";

// API to get program by id
const readProgram = (id) => {
    return axios.get(API_PROGRAM_URL + id, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get all programs
const readPrograms = () => {
    return axios.get(API_PROGRAM_URL, {
        headers: authHeader()
    }).then(res => res.data);
}


// API to create program
const createProgram = (program) => {
    return axios
        .post(API_PROGRAM_URL, program, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to update program
const updateProgram = (id, program) => {
    return axios
        .put(API_PROGRAM_URL + id, program, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to delete program
const deleteProgram = (id) => {
    return axios
        .delete(API_PROGRAM_URL + id, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to disable program
const disableProgram = (id) => {
    return axios
        .put(API_PROGRAM_URL + id + '/disable', {}, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to enable program
const enableProgram = (id) => {
    return axios
        .put(API_PROGRAM_URL + id + '/enable', {}, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to search programs
const searchPrograms = (search) => {
    return axios
        .get(API_PROGRAM_URL + 'search/?q=' + search, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

const program = {
    createProgram,
    readProgram,
    readPrograms,
    updateProgram,
    deleteProgram,
    enableProgram,
    disableProgram,
    searchPrograms
};

export default program;