import React, { useEffect, useState } from 'react';
import MemberSidebar from './components/member.sidebar.component';
import { PageHeader } from '@ant-design/pro-layout';
import { Layout, Button, Form, Input, message, Switch, Select } from 'antd';
import MemberFooter from './components/member.footer.component';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { formatSwitchInForm } from '../helpers/form';
import { createCredential, readCredential, updateCredential } from '../actions/credential';

const formLayout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 16,
    },
};

function Credential() {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const defaultNewCredentialTitle = 'New Credential';
    const defaultNewCredentialSubtitle = 'Create new credential manually';
    const defaultSubmitText = 'Submit';
    const [pageTitle, setPageTitle] = useState(defaultNewCredentialTitle);
    const [pageSubtitle, setPageSubtitle] = useState(defaultNewCredentialSubtitle);
    const [submitText, setSubmitText] = useState(defaultSubmitText);
    const [dataSource, setDataSource] = useState(null);
    const { Content } = Layout;

    useEffect(() => {
        const credentialId = params.credentialId;
        if (typeof credentialId !== 'undefined') {
            setPageTitle(`Credential - ${credentialId}`);
            setPageSubtitle('');
            setSubmitText('Update');
            dispatch(readCredential(credentialId)).then(response => {
                const result = response.result;
                form.setFieldsValue(result);
                setDataSource(result);
                console.log('read');
            }).catch(error => {
                try {
                    const responseMessage = error.response.data.message;
                    message.error(responseMessage);
                    navigate('/credentials');
                } catch (e) {
                    console.log(e);
                    message.error(e.message);
                    navigate('/credentials');
                }
            });
        } else {
            setPageTitle(defaultNewCredentialTitle);
            setPageSubtitle(defaultNewCredentialSubtitle);
            setSubmitText(defaultSubmitText);
        }
    }, [params]);

    const onFinish = (values) => {
        let formattedValues = formatSwitchInForm(values, ['status']);
        if (typeof params.credentialId !== 'undefined') {
            dispatch(updateCredential(params.credentialId, formattedValues)).then(response => {
                try {
                    const result = response.result;
                    if (typeof result.id !== 'undefined') {
                        message.success('Credential updated successfully');
                        navigate(`/credential/${result.id}`);
                    }
                } catch (e) {
                    console.log(e);
                    message.error(e.message);
                    navigate('/credentials');
                }
            }).catch(error => {
                try {
                    console.log(error);
                    const responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log(e);
                    message.error(e.message);
                }
            });
        } else {
            dispatch(createCredential(formattedValues)).then(response => {
                try {
                    const result = response.result;
                    if (typeof result.id !== 'undefined') {
                        message.success('Credential created successfully');
                        navigate(`/credential/${result.id}`);
                    }
                } catch (e) {
                    console.log(e);
                    message.error(e.message);
                    navigate('/credentials');
                }
            }).catch(error => {
                try {
                    console.log(error);
                    const responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log(e);
                    message.error(e.message);
                }
            });
        }
    }

    const onFinishFailed = ({ values, errorFields, outOfDate }) => {
        console.log('Failed:', errorFields);
        if (Array.isArray(errorFields)) {
            errorFields.forEach(field => {
                if (typeof field.errors !== 'undefined' && Array.isArray(field.errors) && field.errors.length > 0) {
                    field.errors.forEach(error => {
                        message.error(error);
                    });
                }
            });
        }
    }
    let extraButtons = [
        <Button key="cancel" type="primary" danger onClick={() => navigate('/credentials')}>Cancel</Button>,
        <Button key="submit" type="primary" htmlType="submit">{submitText}</Button>,
    ];


    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} {...formLayout}>
                    <div className="site-layout-background">
                        <PageHeader
                            className="site-page-header"
                            onBack={() => navigate('/credentials')}
                            title={pageTitle}
                            subTitle={pageSubtitle}
                            extra={extraButtons}
                        />
                        <Form.Item
                            label="Platform"
                            name="platform"
                            required={true}
                            rules={[{ required: true, message: 'Please input a platform!' }]}
                        >
                            <Select allowClear>
                                <Select.Option value="ios">iOS</Select.Option>
                                <Select.Option value="android">Android</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Name"
                            name="name"
                            required={true}
                            rules={[{ required: true, message: 'Please input an credential name!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Status"
                            name="status"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                        <Form.Item
                            dependencies={['platform']}
                            noStyle
                        >
                            {() => {
                                let type = form.getFieldValue('platform');
                                if (type === 'ios') {
                                    return <>
                                        <Form.Item
                                            label="Pass Type ID"
                                            name={["ios", "passTypeId"]}
                                            required={true}
                                            rules={[
                                                { required: true, message: 'Please input a pass type ID!' },
                                                // Only allow hypen and lowercase letters and numbers and underscore
                                                { pattern: /^pass\.[a-zA-Z0-9]+\.[a-zA-Z0-9]+$/, message: 'Please input a valid pass type ID! /^pass\\.[a-zA-Z0-9]+\\.[a-zA-Z0-9]+$/' }
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label="Private Key"
                                            name={["ios", "privateKey"]}
                                            required={true}
                                            rules={[{ required: true, message: 'Please input a private key!' }]}
                                        >
                                            <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }} />
                                        </Form.Item>
                                        <Form.Item
                                            label="Team Identifier"
                                            name={["ios", "teamIdentifier"]}
                                            required={true}
                                            rules={[
                                                { required: true, message: 'Please input a team identifier!' },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label="Signer"
                                            name={["ios", "signer"]}
                                            required={true}
                                            rules={[{ required: true, message: 'Please input a signer!' }]}
                                        >
                                            <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }} />
                                        </Form.Item>
                                    </>
                                } else {
                                    return <>
                                        {type} - TODO
                                    </>
                                }
                            }}
                        </Form.Item>
                    </div>
                </Form>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default Credential;