import authHeader from './auth-header';
import axios from "axios";
import { API_REGISTRATION_URL } from "./config";

// API to get registration by id
const readRegistration = (id) => {
    return axios.get(API_REGISTRATION_URL + id, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get all registrations
const readRegistrations = () => {
    return axios.get(API_REGISTRATION_URL, {
        headers: authHeader()
    }).then(res => res.data);
}


// API to create registration
const createRegistration = (registration) => {
    return axios
        .post(API_REGISTRATION_URL, registration, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to update registration
const updateRegistration = (id, registration) => {
    return axios
        .put(API_REGISTRATION_URL + id, registration, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to delete registration
const deleteRegistration = (id) => {
    return axios
        .delete(API_REGISTRATION_URL + id, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to disable registration
const disableRegistration = (id) => {
    return axios
        .put(API_REGISTRATION_URL + id + '/disable', {}, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to enable registration
const enableRegistration = (id) => {
    return axios
        .put(API_REGISTRATION_URL + id + '/enable', {}, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to search registrations
const searchRegistrations = (search) => {
    return axios
        .get(API_REGISTRATION_URL + 'search/?q=' + search, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

const registration = {
    createRegistration,
    readRegistration,
    readRegistrations,
    updateRegistration,
    deleteRegistration,
    enableRegistration,
    disableRegistration,
    searchRegistrations
};

export default registration;