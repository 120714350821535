import React, { useEffect, useState } from 'react';
import MemberSidebar from './components/member.sidebar.component';
import { PageHeader } from '@ant-design/pro-layout';
import { Layout, Button, Form, Input, message, Switch } from 'antd';
import MemberFooter from './components/member.footer.component';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { formatSwitchInForm } from '../helpers/form';
import { green } from '@ant-design/colors';
import { createOrganization, readOrganization, updateOrganization } from '../actions/organization';

const formLayout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 16,
    },
};

function Organization() {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const defaultNewOrganizationTitle = 'New Organization';
    const defaultNewOrganizationSubtitle = 'Create new organization manually';
    const defaultSubmitText = 'Submit';
    const [pageTitle, setPageTitle] = useState(defaultNewOrganizationTitle);
    const [pageSubtitle, setPageSubtitle] = useState(defaultNewOrganizationSubtitle);
    const [submitText, setSubmitText] = useState(defaultSubmitText);
    const [dataSource, setDataSource] = useState(null);
    const { Content } = Layout;

    useEffect(() => {
        const organizationId = params.organizationId;
        if (typeof organizationId !== 'undefined') {
            setPageTitle(`Organization - ${organizationId}`);
            setPageSubtitle('');
            setSubmitText('Update');
            dispatch(readOrganization(organizationId)).then(response => {
                const result = response.result;
                form.setFieldsValue(result);
                setDataSource(result);
                console.log('read');
            }).catch(error => {
                try {
                    const responseMessage = error.response.data.message;
                    message.error(responseMessage);
                    navigate('/organizations');
                } catch (e) {
                    console.log(e);
                    message.error(e.message);
                    navigate('/organizations');
                }
            });
        } else {
            setPageTitle(defaultNewOrganizationTitle);
            setPageSubtitle(defaultNewOrganizationSubtitle);
            setSubmitText(defaultSubmitText);
        }
    }, [params]);

    const onFinish = (values) => {
        let formattedValues = formatSwitchInForm(values, ['status']);
        if (typeof params.organizationId !== 'undefined') {
            dispatch(updateOrganization(params.organizationId, formattedValues)).then(response => {
                try {
                    const result = response.result;
                    if (typeof result.id !== 'undefined') {
                        message.success('Organization updated successfully');
                        navigate(`/organization/${result.id}`);
                    }
                } catch (e) {
                    console.log(e);
                    message.error(e.message);
                    navigate('/organizations');
                }
            }).catch(error => {
                try {
                    console.log(error);
                    const responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log(e);
                    message.error(e.message);
                }
            });
        } else {
            dispatch(createOrganization(formattedValues)).then(response => {
                try {
                    const result = response.result;
                    if (typeof result.id !== 'undefined') {
                        message.success('Organization created successfully');
                        navigate(`/organization/${result.id}`);
                    }
                } catch (e) {
                    console.log(e);
                    message.error(e.message);
                    navigate('/organizations');
                }
            }).catch(error => {
                try {
                    console.log(error);
                    const responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log(e);
                    message.error(e.message);
                }
            });
        }
    }

    const onFinishFailed = ({ values, errorFields, outOfDate }) => {
        console.log('Failed:', errorFields);
        if (Array.isArray(errorFields)) {
            errorFields.forEach(field => {
                if (typeof field.errors !== 'undefined' && Array.isArray(field.errors) && field.errors.length > 0) {
                    field.errors.forEach(error => {
                        message.error(error);
                    });
                }
            });
        }
    }
    let extraButtons = [
        <Button key="cancel" type="primary" danger onClick={() => navigate('/organizations')}>Cancel</Button>,
        <Button key="submit" type="primary" htmlType="submit">{submitText}</Button>,
    ];
    if (typeof dataSource !== 'undefined' && dataSource) {
        extraButtons.push(<Button key="clients" type="priamry" style={{ backgroundColor: green[5] }} onClick={() => {
            navigate(`/clients?organization=${dataSource.id}`);
        }}>Clients</Button>);
    }


    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} {...formLayout}>
                    <div className="site-layout-background">
                        <PageHeader
                            className="site-page-header"
                            onBack={() => navigate('/organizations')}
                            title={pageTitle}
                            subTitle={pageSubtitle}
                            extra={extraButtons}
                        />
                        <Form.Item
                            label="Name"
                            name="name"
                            required={true}
                            rules={[{ required: true, message: 'Please input an organization name!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Slug"
                            name="slug"
                            required={true}
                            rules={[
                                { required: true, message: 'Please input an organization slug!' },
                                // Only allow hypen and lowercase letters and numbers and underscore
                                { pattern: /^[a-z0-9_-]+$/, message: 'Please input a valid organization slug! /^[a-z0-9_-]+$/' }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Status"
                            name="status"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    </div>
                </Form>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default Organization;