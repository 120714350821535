import React, { useEffect, useState } from 'react';
import { Layout, Popconfirm, message, Button, Table, Space } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useNavigate } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch } from 'react-redux';
import { readCredentials, deleteCredential, disableCredential, enableCredential } from '../actions/credential.js';
import { green, red } from '@ant-design/colors';
import {
    CheckOutlined,
    CloseOutlined
} from '@ant-design/icons';


function Credentials() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { Content } = Layout;
    const [reloadFlag, setReloadFlag] = useState(new Date());
    const [dataSource, setDataSource] = useState([]);

    const deleteCredentialAction = (id) => {
        dispatch(deleteCredential(id)).then(response => {
            message.success('Credential deleted successfully');
            setReloadFlag(new Date());
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(e.message);
            }
        });
    }
    const enableCredentialAction = (id) => {
        dispatch(enableCredential(id)).then(response => {
            message.success('Credential enabled successfully');
            setReloadFlag(new Date());
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(e.message);
            }
        });

    }
    const disableCredentialAction = (id) => {
        dispatch(disableCredential(id)).then(response => {
            message.success('Credential is disabled successfully');
            setReloadFlag(new Date());
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(e.message);
            }
        });
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Platform',
            dataIndex: 'platform',
            key: 'platform',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Information',
            dataIndex: 'information',
            key: 'infomration',
            render: (text, record) => {
                if (record.platform === 'ios') {
                    if (typeof record.ios !== 'undefined') {
                        return record.ios.passTypeId;
                    } else {
                        return <span style={{ color: red[5] }}>ERROR!</span>
                    }
                } else {
                    return 'N/A';
                }
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                if (record.status) {
                    return <CheckOutlined style={{ color: green[5] }} />;
                } else {
                    return <CloseOutlined style={{ color: red[5] }} />;
                }
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return <Space>
                    <Button type="primary" onClick={() => navigate(`/credential/${record.id}`)}>Edit</Button>
                    <Popconfirm
                        title="Are you sure to delete this credential?"
                        onConfirm={() => deleteCredentialAction(record.id)}
                        okText="Delete"
                        cancelText="Cancel"
                    >
                        <Button danger type="primary">Delete</Button>
                    </Popconfirm>
                    {record.status ? <Popconfirm
                        title="Are you sure to disable this credential?"
                        onConfirm={() => disableCredentialAction(record.id)}
                        okText="Disable"
                        cancelText="Cancel"
                    ><Button type="primary" danger>Disable</Button></Popconfirm> : <Popconfirm
                        title="Are you sure to enable this credential?"
                        onConfirm={() => enableCredentialAction(record.id)}
                        okText="Enable"
                        cancelText="Cancel"
                    ><Button type="primary">Enable</Button></Popconfirm>}
                </Space>
            }
        }
    ];

    useEffect(() => {
        dispatch(readCredentials()).then((response) => {
            setDataSource(response.result);
        }).catch((error) => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(e.message);
            }
        });
    }, [dispatch, reloadFlag]);

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <PageHeader
                        className="site-page-header"
                        onBack={() => navigate('/dashboard')}
                        title="Credentials"
                        subTitle="Manage credentials"
                        extra={[
                            <Button key="create" type="primary" onClick={() => navigate('/credential')}>New</Button>
                        ]}
                    />
                    <Table
                        rowKey={record => record.id}
                        dataSource={dataSource}
                        columns={columns} />
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default Credentials;