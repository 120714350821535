import React, { useEffect, useState } from 'react';
import { Layout, Popconfirm, message, Button, Table, Space } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { Link, useNavigate } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch } from 'react-redux';
import { readPrograms, deleteProgram, disableProgram, enableProgram } from '../actions/program.js';
import { green, red } from '@ant-design/colors';
import {
    CheckOutlined,
    CloseOutlined
} from '@ant-design/icons';


function Programs() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { Content } = Layout;
    const [reloadFlag, setReloadFlag] = useState(new Date());
    const [dataSource, setDataSource] = useState([]);

    const deleteProgramAction = (id) => {
        dispatch(deleteProgram(id)).then(response => {
            message.success('Program deleted successfully');
            setReloadFlag(new Date());
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(e.message);
            }
        });
    }
    const enableProgramAction = (id) => {
        dispatch(enableProgram(id)).then(response => {
            message.success('Program enabled successfully');
            setReloadFlag(new Date());
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(e.message);
            }
        });

    }
    const disableProgramAction = (id) => {
        dispatch(disableProgram(id)).then(response => {
            message.success('Program is disabled successfully');
            setReloadFlag(new Date());
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(e.message);
            }
        });
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Slug',
            dataIndex: 'slug',
            key: 'slug',
        },
        {
            title: 'Organization',
            dataIndex: 'organization',
            key: 'organization',
            render: (text, record) => {
                if (typeof record.organization === 'object') {
                    return <Link to={`/organization/${record.organization._id}`} style={{ color: record.organization.status ? green[5] : red[5] }}>{record.organization.name}</Link>;
                } else {
                    return <Link to={`/organization/${record.organization}`}>{record.organization}</Link>;
                }
            }
        },
        {
            title: 'Apple Credential',
            dataIndex: 'appleCredential',
            key: 'appleCredential',
            render: (text, record) => {
                if (typeof record.appleCredential === 'object') {
                    return <Link to={`/credential/${record.appleCredential._id}`} style={{ color: record.appleCredential.status ? green[5] : red[5] }}>{record.appleCredential.name}</Link>;
                } else {
                    return <Link to={`/credential/${record.appleCredential}`}>{record.appleCredential}</Link>;
                }
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                if (record.status) {
                    return <CheckOutlined style={{ color: green[5] }} />;
                } else {
                    return <CloseOutlined style={{ color: red[5] }} />;
                }
            }
        },
        {
            title: 'Enabled iOS',
            dataIndex: 'enableIOS',
            key: 'enableIOS',
            render: (text, record) => {
                if (record.enableIOS) {
                    return <CheckOutlined style={{ color: green[5] }} />;
                } else {
                    return <CloseOutlined style={{ color: red[5] }} />;
                }
            }
        },
        {
            title: 'Enabled Android',
            dataIndex: 'enableGoogle',
            key: 'enableGoogle',
            render: (text, record) => {
                if (record.enableGoogle) {
                    return <CheckOutlined style={{ color: green[5] }} />;
                } else {
                    return <CloseOutlined style={{ color: red[5] }} />;
                }
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return <Space>
                    <Button type="primary" onClick={() => navigate(`/program/${record.id}`)}>Edit</Button>
                    <Popconfirm
                        title="Are you sure to delete this program?"
                        onConfirm={() => deleteProgramAction(record.id)}
                        okText="Delete"
                        cancelText="Cancel"
                    >
                        <Button danger type="primary">Delete</Button>
                    </Popconfirm>
                    {record.status ? <Popconfirm
                        title="Are you sure to disable this program?"
                        onConfirm={() => disableProgramAction(record.id)}
                        okText="Disable"
                        cancelText="Cancel"
                    ><Button type="primary" danger>Disable</Button></Popconfirm> : <Popconfirm
                        title="Are you sure to enable this program?"
                        onConfirm={() => enableProgramAction(record.id)}
                        okText="Enable"
                        cancelText="Cancel"
                    ><Button type="primary">Enable</Button></Popconfirm>}
                    <Button type="priamry" style={{ backgroundColor: green[5] }} onClick={() => {
                        navigate(`/clients?program=${record.id}`);
                    }}>Clients</Button>
                </Space>
            }
        }
    ];

    useEffect(() => {
        dispatch(readPrograms()).then((response) => {
            setDataSource(response.result);
        }).catch((error) => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(e.message);
            }
        });
    }, [dispatch, reloadFlag]);

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <PageHeader
                        className="site-page-header"
                        onBack={() => navigate('/dashboard')}
                        title="Programs"
                        subTitle="Manage programs"
                        extra={[
                            <Button key="create" type="primary" onClick={() => navigate('/program')}>New</Button>
                        ]}
                    />
                    <Table
                        rowKey={record => record.id}
                        dataSource={dataSource}
                        columns={columns} />
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default Programs;