import React, { useEffect, useState } from 'react';
import { Layout, Popconfirm, message, Button, Table, Space } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { Link, useNavigate } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch } from 'react-redux';
import { readPasses, deletePass } from '../actions/pass.js';
import { green, red } from '@ant-design/colors';
import {
    CheckOutlined,
    CloseOutlined
} from '@ant-design/icons';


function Passes() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { Content } = Layout;
    const [reloadFlag, setReloadFlag] = useState(new Date());
    const [dataSource, setDataSource] = useState([]);

    const deletePassAction = (id) => {
        dispatch(deletePass(id)).then(response => {
            message.success('Pass deleted successfully');
            setReloadFlag(new Date());
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(e.message);
            }
        });
    }

    const columns = [
        {
            title: 'UUID',
            dataIndex: 'uuid',
            key: 'uuid',
        },
        {
            title: 'Program Name',
            dataIndex: 'programName',
            key: 'programName',
            render: (text, record) => {
                return <Link to={`/program/${record.program._id}`}>{record.program.name}</Link>
            }
        },
        {
            title: 'Program Type',
            dataIndex: 'programType',
            key: 'programType',
            render: (text, record) => {
                return <span>{record.program.type}</span>
            }
        },
        {
            title: 'Organization',
            dataIndex: 'organization',
            key: 'organization',
            render: (text, record) => {
                return <Link to={`/organization/${record.organization._id}`}>{record.organization.name}</Link>
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return <Space>
                    <Button type="primary" onClick={() => navigate(`/pass/${record.id}`)}>Edit</Button>
                    <Button style={{ backgroundColor: green[5] }} type="primary" onClick={() => navigate(`/registrations?pass=${record.id}`)}>Registrations</Button>
                    <Popconfirm
                        title="Are you sure to delete this pass?"
                        onConfirm={() => deletePassAction(record.id)}
                        okText="Delete"
                        cancelText="Cancel"
                    >
                        <Button danger type="primary">Delete</Button>
                    </Popconfirm>
                    {/* <Button type="priamry" style={{ backgroundColor: green[5] }} onClick={() => {
                        navigate(`/clients?pass=${record.id}`);
                    }}>Clients</Button> */}
                </Space>
            }
        }
    ];

    useEffect(() => {
        dispatch(readPasses()).then((response) => {
            setDataSource(response.result);
        }).catch((error) => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(e.message);
            }
        });
    }, [dispatch, reloadFlag]);

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <PageHeader
                        className="site-page-header"
                        onBack={() => navigate('/dashboard')}
                        title="Passes"
                        subTitle="Manage passes"
                        extra={[
                            // <Button key="create" type="primary" onClick={() => navigate('/pass')}>New</Button>
                        ]}
                    />
                    <Table
                        rowKey={record => record.id}
                        dataSource={dataSource}
                        columns={columns} />
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default Passes;