function formatSwitchInForm(values, keyOrKeys) {
    if (typeof keyOrKeys !== 'undefined') {
        if (Array.isArray(keyOrKeys)) {
            keyOrKeys.forEach(key => {
                if (typeof values[key] !== 'undefined') {
                    // ignore
                } else {
                    values[key] = false;
                }
            });
        } else if (typeof keyOrKeys === 'string') {
            if (typeof values[keyOrKeys] !== 'undefined') {
                // ignore
            } else {
                values[keyOrKeys] = false;
            }
        }
    }
    return values;
}
export {
    formatSwitchInForm
}