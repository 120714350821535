import React, { useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Layout, message } from 'antd';
import { useDispatch } from 'react-redux';
import { currentUser as getCurrentUser, logout } from "../actions/auth";
import {
    Routes,
    Route
} from "react-router-dom";
import Dashboard from './dashboard.page';
import Profile from './profile.page';
import { isRootOrTopAdmin } from '../helpers/user';
import Admins from './admins.page';

import Organizations from './organizations.page';
import Organization from './organization.page';
import Clients from './clients.page';
import Client from './client.page';
import Credentials from './credentials.page';
import Credential from './credential.page';
import Programs from './programs.page';
import Program from './program.page';

import Passes from './passes.page';
// import Pass from './pass.page';
import Registrations from './registrations.page';
import AppleDevices from './apple.devices.page';


function MemberSection() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const currentUser = useSelector(state => state.auth.user);

    // Check whether should recirect to login
    // And also need to redirect to change password first
    useEffect(() => {
        if (!isLoggedIn || !currentUser) {
            navigate('/login');
        } else {
            if (typeof currentUser.needChangePassword === 'undefined' || currentUser.needChangePassword === null || currentUser.needChangePassword === true) {
                navigate('/update-password');
            }
        }
    }, [isLoggedIn, currentUser, navigate]);

    // Every restricted page should check whether the access token is stil valid.
    useEffect(() => {
        dispatch(getCurrentUser()).then(() => {

        }).catch((error) => {
            try {
                const errorMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                message.error(errorMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(e.message);
            }
            dispatch(logout())
        });
    }, [location.pathname, dispatch]);

    // Undetermined state need to render empty content
    if (!isLoggedIn || !currentUser) {
        return null;
    } else {
        return <Layout className="admin-layout" style={{ minHeight: '100vh' }}>
            <Routes>
                {isRootOrTopAdmin(currentUser) &&
                    <>
                        {/* <Route path="organizations" element={<Entities />} />
                        <Route path="organization/:id" element={<Entity />} />
                        <Route path="logs" element={<Logs />} />
                        <Route path="roles" element={<Roles />} />
                        <Route path="role" element={<Role />} />
                        <Route path="role/:id" element={<Role />} /> */}
                        <Route path="admins" element={<Admins />} />
                        {/* <Route path="admin" element={<Admin />} />
                        <Route path="admin/:id" element={<Admin />} /> */}

                        {/* Manage the clients under each organization */}
                        <Route path="clients" element={<Clients />} />
                        <Route path="client" element={<Client />} />
                        <Route path="client/:clientId" element={<Client />} />

                        {/* Manage the business that use our program */}
                        <Route path="organizations" element={<Organizations />} />
                        <Route path="organization/:organizationId" element={<Organization />} />
                        <Route path="organization" element={<Organization />} />

                        {/* Manage the credentials used to sign the pass */}
                        <Route path="credentials" element={<Credentials />} />
                        <Route path="credential" element={<Credential />} />
                        <Route path="credential/:credentialId" element={<Credential />} />

                        {/* Manage the program under each organization */}
                        <Route path="programs" element={<Programs />} />
                        <Route path="program" element={<Program />} />
                        <Route path="program/:programId" element={<Program />} />

                        {/* Manage the pass under each program */}
                        <Route path="passes" element={<Passes />} />
                        {/* <Route path="pass" element={<Pass />} />
                        <Route path="pass/:passId" element={<Pass />} /> */}

                        {/* Manage registrations of device for the pass */}
                        <Route path="registrations" element={<Registrations />} />
                        <Route path="apple-devices" element={<AppleDevices />} />
                    </>
                }
                <Route path="profile" element={<Profile />} />
                <Route path="*" element={<Dashboard />} />
            </Routes>
        </Layout>;
    }
}

export default MemberSection;