import { Layout, Form, Input, Button, Row, Col, message } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import './firstUpdatePassword.page.scss';
import { firstUpdatePassword } from "../actions/auth";
import { useEffect } from 'react';

const FirstUpdatePassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const currentUser = useSelector(state => state.auth.user);

    const onFinish = (values) => {
        console.log('[firstUpdatePassword]', values);
        dispatch(firstUpdatePassword(currentUser ? currentUser.id : null, values.password)).then(() => {
            navigate('/dashboard');
        }).catch((error) => {
            try {
                const errorMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                message.error(errorMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(e.message);
            }
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        message.error(JSON.stringify(errorInfo));
    };

    useEffect(() => {
        if (!isLoggedIn || !currentUser) {
            navigate('/login');
        } else {
            if (typeof currentUser.needChangePassword !== 'undefined' && currentUser.needChangePassword === false) {
                navigate('/dashboard');
            }
        }
    }, [isLoggedIn, currentUser, navigate]);

    if (!isLoggedIn || !currentUser) {
        return null;
    } else {
        return <Layout className="admin-layout admin-layout--firstUpdate" style={{ minHeight: '100vh' }}>
            <Form
                name="basic"
                labelCol={{
                    // span: 12,
                }}
                wrapperCol={{
                    span: 6,
                    offset: 9
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Row>
                    <Col span={6} offset={9}>
                        <h1 className="admin-layout__title">Change Your Password</h1>
                    </Col>
                </Row>

                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input.Password placeholder="Password" />
                </Form.Item>

                <Form.Item
                    name="confirm-password"
                    dependencies={['password']}
                    rules={[
                        {
                            required: true,
                            message: 'Please repeat your password!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password placeholder="Repeat Your Password" />
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        offset: 9,
                        span: 6,
                    }}
                >
                    <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                        Update
                    </Button>
                </Form.Item>
            </Form>
        </Layout>;
    }
};

export default FirstUpdatePassword;