function isRoot(user) {
    try {
        if (user) {
            if (typeof user.isRoot !== 'undefined' && user.isRoot === true) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } catch (e) {
        console.log(e);
        return false;
    }
}

function isTopAdmin(user) {
    try {
        if (user) {
            if (typeof user.isTopAdmin !== 'undefined' && user.isTopAdmin === true) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } catch (e) {
        console.log(e);
        return false;
    }
}

function isRootOrTopAdmin(user) {
    return isTopAdmin(user) || isRoot(user);
}

export {
    isRoot,
    isTopAdmin,
    isRootOrTopAdmin
};