import OrganizationService from "../services/organization.service";

// Action to get organization by id
export const readOrganization = (id) => (dispatch) => {
    return OrganizationService.readOrganization(id).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to search organizations
export const searchOrganizations = (search) => (dispatch) => {
    return OrganizationService.searchOrganizations(search).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all organizations
export const readOrganizations = () => (dispatch) => {
    return OrganizationService.readOrganizations().then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to create the organization
export const createOrganization = (organization) => (dispatch) => {
    return OrganizationService.createOrganization(organization).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        console.log(error.message);
        return Promise.reject(error);
    });
}

// Action to update the organization
export const updateOrganization = (id, organization) => (dispatch) => {
    return OrganizationService.updateOrganization(id, organization).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to delete the organization
export const deleteOrganization = (id) => (dispatch) => {
    return OrganizationService.deleteOrganization(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Actino to enable the organization
export const enableOrganization = (id) => (dispatch) => {
    return OrganizationService.enableOrganization(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to disable the organization
export const disableOrganization = (id) => (dispatch) => {
    return OrganizationService.disableOrganization(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}