import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    FIRST_UPDATE_PASSWORD_FAIL,
    FIRST_UPDATE_PASSWORD_SUCCESS,
    PROFILE_UPDATE_NAME_SUCCESS,
    PROFILE_UPDATE_NAME_FAIL,
    CURRENT_USER_SUCCESS,
    CURRENT_USER_FAIL
} from "./types";

import AuthService from "../services/auth.service";

// Action to reset password
export const reset = (userId, token, password) => (dispatch) => {
    return AuthService.reset(userId, token, password).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to update the password in first login
export const firstUpdatePassword = (userId, password) => (dispatch) => {
    return AuthService.firstUpdatePassword(userId, password).then((data) => {
        dispatch({
            type: FIRST_UPDATE_PASSWORD_SUCCESS,
            payload: { user: data },
        });
        return Promise.resolve(data);
    }, (error) => {
        dispatch({
            type: FIRST_UPDATE_PASSWORD_FAIL,
        });
        return Promise.reject(error);
    });
}

// Action to update name in profile
export const profileUpdateName = (userId, name) => (dispatch) => {
    return AuthService.profileUpdateName(userId, name).then((data) => {
        dispatch({
            type: PROFILE_UPDATE_NAME_SUCCESS,
            payload: { user: data },
        });
        return Promise.resolve(data);
    }, (error) => {
        dispatch({
            type: PROFILE_UPDATE_NAME_FAIL,
        });
        return Promise.reject(error);
    });
}

// Action to update password in profile
export const profileUpdatePassword = (userId, oldPassword, newPassword) => (dispatch) => {
    return AuthService.profileUpdatePassword(userId, oldPassword, newPassword).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get the current user information
export const currentUser = () => (dispatch) => {
    return AuthService.currentUser().then((data) => {
        dispatch({
            type: CURRENT_USER_SUCCESS,
            payload: { user: data },
        });
        return Promise.resolve(data);
    }, (error) => {
        console.log(error);
        dispatch({
            type: CURRENT_USER_FAIL
        });
        return Promise.reject(error);
    });
}

// Action for user login
export const login = (email, password) => (dispatch) => {
    return AuthService.login(email, password).then(
        (data) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: { user: data }
            });

            return Promise.resolve();
        },
        (error) => {
            dispatch({
                type: LOGIN_FAIL,
            });
            return Promise.reject(error);
        }
    );
};

// Action to logout
export const logout = () => (dispatch) => {
    AuthService.logout();
    dispatch({
        type: LOGOUT,
    });
};