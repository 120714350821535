import React, { useEffect, useState } from 'react';
import MemberSidebar from './components/member.sidebar.component';
import { PageHeader } from '@ant-design/pro-layout';
import { Layout, Button, Form, Input, message, Switch } from 'antd';
import MemberFooter from './components/member.footer.component';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { formatSwitchInForm } from '../helpers/form';
import { createClient, readClient, updateClient } from '../actions/client';
import SearchSelectField from './components/field.select';
import { searchOrganizations } from '../actions/organization';

const formLayout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 16,
    },
};

function Client() {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const defaultNewClientTitle = 'New Client';
    const defaultNewClientSubtitle = 'Create new client manually';
    const defaultSubmitText = 'Submit';
    const [pageTitle, setPageTitle] = useState(defaultNewClientTitle);
    const [pageSubtitle, setPageSubtitle] = useState(defaultNewClientSubtitle);
    const [submitText, setSubmitText] = useState(defaultSubmitText);
    const [dataSource, setDataSource] = useState(null);
    const { Content } = Layout;
    const [organizationOptions, setOrganizationOptions] = useState(null);

    useEffect(() => {
        const clientId = params.clientId;
        if (typeof clientId !== 'undefined') {
            setPageTitle(`Client - ${clientId}`);
            setPageSubtitle(``);
            setSubmitText('Update');
            dispatch(readClient(clientId)).then(response => {
                let result = response.result;
                if (typeof result.organization !== 'undefined') {
                    if (typeof result.organization._id !== 'undefined') {
                        setOrganizationOptions([{
                            label: `${result.organization.name} (${result.organization.slug})`,
                            value: result.organization._id,
                        }]);
                        result.organization = result.organization._id;
                    }
                }
                form.setFieldsValue(result);
                setDataSource(result);
            }).catch(error => {
                try {
                    const responseMessage = error.response.data.message;
                    message.error(responseMessage);
                    navigate('/clients');
                } catch (e) {
                    console.log(e);
                    message.error(e.message);
                    navigate('/clients');
                }
            });
        } else {
            setPageTitle(defaultNewClientTitle);
            setPageSubtitle(defaultNewClientSubtitle);
            setSubmitText(defaultSubmitText);
        }
    }, [params]);

    const onFinish = (values) => {
        console.log(values);
        let formattedValues = formatSwitchInForm(values, ['status', 'isRootClient', 'isTopClient']);
        console.log(formattedValues);
        if (typeof params.clientId !== 'undefined') {
            dispatch(updateClient(params.clientId, formattedValues)).then(response => {
                try {
                    const result = response.result;
                    if (typeof result.id !== 'undefined') {
                        message.success('Client updated successfully');
                        navigate(`/client/${result.id}`);
                    }
                } catch (e) {
                    console.log(e);
                    message.error(e.message);
                    navigate('/clients');
                }
            }).catch(error => {
                try {
                    console.log(error);
                    const responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log(e);
                    message.error(e.message);
                }
            });
        } else {
            dispatch(createClient(formattedValues)).then(response => {
                try {
                    const result = response.result;
                    if (typeof result.id !== 'undefined') {
                        message.success('Client created successfully');
                        navigate(`/client/${result.id}`);
                    }
                } catch (e) {
                    console.log(e);
                    message.error(e.message);
                    navigate('/clients');
                }
            }).catch(error => {
                try {
                    console.log(error);
                    const responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log(e);
                    message.error(e.message);
                }
            });
        }
    }

    const onFinishFailed = ({ values, errorFields, outOfDate }) => {
        console.log('Failed:', errorFields);
        if (Array.isArray(errorFields)) {
            errorFields.forEach(field => {
                if (typeof field.errors !== 'undefined' && Array.isArray(field.errors) && field.errors.length > 0) {
                    field.errors.forEach(error => {
                        message.error(error);
                    });
                }
            });
        }
    }
    let extraButtons = [
        <Button key="cancel" type="primary" danger onClick={() => navigate('/clients')}>Cancel</Button>,
        <Button key="submit" type="primary" htmlType="submit">{submitText}</Button>,
    ];


    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} {...formLayout}>
                    <div className="site-layout-background">
                        <PageHeader
                            className="site-page-header"
                            onBack={() => navigate('/clients')}
                            title={pageTitle}
                            subTitle={pageSubtitle}
                            extra={extraButtons}
                        />
                        <Form.Item
                            label="Organization"
                            name="organization"
                            required={true}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input organization name',
                                },
                            ]}
                        >
                            <SearchSelectField
                                showSearch={true}
                                placeholder="Select organization"
                                allowClear={true}
                                fetchOptions={(value) => dispatch(searchOrganizations(value))}
                                defaultOptions={organizationOptions}
                                displayOptions={(data) => {
                                    try {
                                        return data.result.map((item) => {
                                            return {
                                                label: `${item.name} (${item.slug})`,
                                                value: item.id,
                                            };
                                        });
                                    } catch (e) {
                                        console.log(e);
                                        return [];
                                    }
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Name"
                            name="name"
                            required={true}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input client name',
                                },
                            ]}
                        >
                            <Input placeholder="Client name" />
                        </Form.Item>
                        <Form.Item
                            label="Email"
                            name="email"
                            required={true}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input client email',
                                },
                                {
                                    type: 'email',
                                    message: 'Please input a valid email',
                                }
                            ]}
                        >
                            <Input type="email" placeholder="Client email" />
                        </Form.Item>
                        <Form.Item
                            label="Is Root Client?"
                            name="isRootClient"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                        <Form.Item
                            label="Is Top Client?"
                            name="isTopClient"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                        <Form.Item
                            label="Status"
                            name="status"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    </div>
                </Form>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default Client;