import React, { useEffect, useState } from 'react';
import { Layout, Popconfirm, message, Button, Table, Space } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { Link, useNavigate } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch } from 'react-redux';
import { readRegistrations, deleteRegistration, disableRegistration, enableRegistration } from '../actions/registration.js';
import { green, red } from '@ant-design/colors';
import {
    CheckOutlined,
    CloseOutlined
} from '@ant-design/icons';


function Registrations() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { Content } = Layout;
    const [reloadFlag, setReloadFlag] = useState(new Date());
    const [dataSource, setDataSource] = useState([]);

    const deleteRegistrationAction = (id) => {
        dispatch(deleteRegistration(id)).then(response => {
            message.success('Registration deleted successfully');
            setReloadFlag(new Date());
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(e.message);
            }
        });
    }
    const enableRegistrationAction = (id) => {
        dispatch(enableRegistration(id)).then(response => {
            message.success('Registration enabled successfully');
            setReloadFlag(new Date());
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(e.message);
            }
        });

    }
    const disableRegistrationAction = (id) => {
        dispatch(disableRegistration(id)).then(response => {
            message.success('Registration is disabled successfully');
            setReloadFlag(new Date());
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(e.message);
            }
        });
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Pass UUID',
            dataIndex: 'passUuid',
            key: 'passUuid',
            render: (text, record) => {
                return <Link to={`/pass/${record.pass._id}`}>{record.pass.uuid}</Link>;
            }
        },
        {
            title: 'Program',
            dataIndex: 'program',
            key: 'program',
            render: (text, record) => {
                return <Link to={`/program/${record.pass.program._id}`}>{record.pass.program.name}</Link>;
            }
        },
        {
            title: 'Organization',
            dataIndex: 'organization',
            key: 'organization',
            render: (text, record) => {
                return <Link to={`/organization/${record.pass.organization._id}`}>{record.pass.organization.name}</Link>;
            }
        },
        {
            title: 'Device Platform',
            dataIndex: 'devicePlatform',
            key: 'devicePlatform',
            render: (text, record) => {
                switch (record.deviceModel) {
                    case 'AppleDevice':
                        return 'iOS';
                    case 'AndroidDevice':
                        return 'Android';
                    case 'Web':
                        return 'Web';
                    default:
                        return 'Unknown';
                }
            }
        },
        {
            title: 'Device',
            dataIndex: 'device',
            key: 'device',
            render: (text, record) => {
                switch (record.deviceModel) {
                    case 'AppleDevice':
                        return <Link to={`/apple-device/${record.device._id}`}>{record.device.deviceLibraryIdentifier}</Link>;
                    case 'AndroidDevice':
                        return 'Android';
                    case 'Web':
                        return 'Web';
                    default:
                        return 'Unknown';
                }
            }
        },
        {
            title: 'Data',
            dataIndex: 'data',
            key: 'data'
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return <Space>
                    <Button type="primary" onClick={() => navigate(`/registration/${record.id}`)}>Edit</Button>
                    <Popconfirm
                        title="Are you sure to delete this registration?"
                        onConfirm={() => deleteRegistrationAction(record.id)}
                        okText="Delete"
                        cancelText="Cancel"
                    >
                        <Button danger type="primary">Delete</Button>
                    </Popconfirm>
                </Space>
            }
        }
    ];

    useEffect(() => {
        dispatch(readRegistrations()).then((response) => {
            setDataSource(response.result);
        }).catch((error) => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(e.message);
            }
        });
    }, [dispatch, reloadFlag]);

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <PageHeader
                        className="site-page-header"
                        onBack={() => navigate('/dashboard')}
                        title="Registrations"
                        subTitle="Manage registrations"
                        extra={[
                            <Button key="create" type="primary" onClick={() => navigate('/registration')}>New</Button>
                        ]}
                    />
                    <Table
                        rowKey={record => record.id}
                        dataSource={dataSource}
                        columns={columns} />
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default Registrations;